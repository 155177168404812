import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["videos"];
  static values = { url: String}
  connect() {
    
    this._render()
    document.addEventListener('event-listener-channels:change', this._reload.bind(this) )
  }

  next(event){
    this._move(event)
  }

  prev(event){
    this._move(event)
  }

  _move(event){
    event.preventDefault()
    event.stopImmediatePropagation()
    let url = $(event.target).closest('a').attr('href')
    this.urlValue = url
    this._render()
  }
  
  _render(){
    fetch(this.urlValue)
    .then(response => response.text())
    .then(html => this.videosTarget.innerHTML = html)
  }

  _reload(){
    const url =  new URL(this.urlValue)
    const searchParams = url.searchParams
    const queryType = searchParams.get('query_type')
    if(queryType === 'sponsored_vods_for_event_listener'){
      searchParams.delete('page')
      searchParams.append('page', 1)
      this.urlValue = url.toString()
      console.log('reload the video stripe widget with url ', this.urlValue)
      this._render()

    }
  }



  
}