const $ = require("jquery")

$(function(){
  
  $('.hero-carousel').each( function(){
    $(this).slick({
      dots: true,
      autoplay: true,
      //appendArrows: $(this).parent().find('.arrows-container')

    })
  });
  $('.slick-carousel').slick({infinite: true,
                              slidesToShow: 4, 
                              slidesToScroll: 1,
                              dots: true,
                              responsive: [
                                {
                                  breakpoint: 768,
                                  settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    infinite: true,
                                  }
                                }
                              ]
                            
                            });
})
