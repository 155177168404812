import { Controller } from 'stimulus';


let toggleVideoInterval;
export default class extends Controller {
  static values = { channelId: String, startsAt: Number, hideDocflixLogo: Boolean, ignoreCountdown: Boolean }
  static targets = ["countdown", "video", "chat"]
  connect(){
    console.log(`cf-stream-controller connecting ${Date.now()}`)
    this.toggleVideo()
  }

  toggleVideo(){
    console.log(`toggleVideo ${Date.now()}`)
    if(!toggleVideoInterval){
      console.log("start toggleVideoInterval")
      toggleVideoInterval = setInterval(this.toggleVideo.bind(this), 3000)
    }
    if(this.hasEventStarted()){
      console.log("event has started. show video player")
      this.videoTarget.classList.remove('is-hidden')
      this.chatTarget.classList.remove('is-hidden')
      this.countdownTarget.classList.add('is-hidden')
      toggleVideoInterval = clearInterval(toggleVideoInterval)
    }
  }

  

  hasEventStarted(){
    if(this.ignoreCountdownValue) return true
    let nowInMs = new Date().getTime()
    // console.log(`${new Date( this.startsAtValue - nowInMs).toISOString().substr(11, 8)}`)
    return (nowInMs > this.startsAtValue)
  }

} 