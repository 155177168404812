import consumer from '../channels/consumer';
import { Controller } from 'stimulus'; 
export default class extends Controller {
  
  static values = { slideShowId: Number }
  static targets = ["nav", "paginate", "playVideosDropdown", "stopVideo"]
  connect(){
    console.log(`${this.identifier} is connected`)
    this.channel = consumer.subscriptions.create({channel: "SlideShowsChannel", slide_show_id: this.slideShowIdValue}, {
      connected: this._cableConnected.bind(this),
      disconnected: this._cableDisconnected.bind(this),
      received: this._cableReceived.bind(this),
    });
    
  }

  nav(event){
    if (event.key === 'ArrowRight') {
      this.next(event)
    }
    if(event.key === 'ArrowLeft'){
      this.prev(event)

    } 
    if(event.keyCode === 32){ //space
      this.next(event)
    }
  }

  playVideo(event){
    console.log("playVideo!!!", event)
    event.preventDefault()
    event.stopImmediatePropagation()
    const url = $(event.target).closest('a').attr('href')
    const csrfToken = $('[name="csrf-token"]')[0].content
    fetch(url, {
        method: "post",
        headers: {
          'X-CSRF-Token': csrfToken
        }
      }).then((response) => {
        console.log("hide the stop button")
        
      })
    
  }

  prev(event){
    event.preventDefault()
    event.stopImmediatePropagation()
    const url = this.paginateTarget.getElementsByClassName('pagination-previous')[0].href
    this._paginate(url)
  }
  next(event){
    event.preventDefault()
    event.stopImmediatePropagation()
    const url = this.paginateTarget.getElementsByClassName('pagination-next')[0].href
    this._paginate(url)
  }

  _paginate(url){
    console.log(`render url: ${url}`)
    const csrfToken = $('[name="csrf-token"]')[0].content
    fetch(url, {
        method: "post",
        headers: {
          'X-CSRF-Token': csrfToken
        }
      })
      .then(response => response.status)
      .then(status  =>  console.log('pagination status:', status))
  }

  _render_nav(){
    fetch(`/slide_shows/${this.slideShowIdValue}/render_nav`)
      .then(response => response.text())
      .then(html => this.navTarget.innerHTML = html)
  }

  _cableConnected() {
    // Called when the subscription is ready for use on the server
  }

  _cableDisconnected() {
    // Called when the subscription has been terminated by the server
  }

  _cableReceived(data) {
    // Called when there's incoming data on the websocket for this channel
    console.log(`${this.identifier} has received a WS message`, data)
    if(data.event === 'paginate'){
      this._render_nav()
    }
    if(data.event === 'playVideo'){
      this.stopVideoTarget.classList.remove('is-hidden')
      this.playVideosDropdownTarget.classList.remove('is-active')
    }
    if(data.event === 'stopVideo'){
      this.stopVideoTarget.classList.add('is-hidden')
      this.playVideosDropdownTarget.classList.remove('is-active')
    }

  }
 
  
}