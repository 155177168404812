import { Controller } from 'stimulus'; 
export default class extends Controller {
  static values = { 
      tourName: String,
      autoStart: Boolean,
    }
  connect(){
    console.log(this.tourNameValue)
    this.introJsInsance = window.introJs()
    //console.log(this.introJsInsance)
    this.introJsInsance.setOptions({
      nextLabel: "Weiter",
      prevLabel: "Zurück",
      doneLabel: "Fertig",
      dontShowAgainLabel: "Zeig mir diese Hilfe nicht mehr",
      dontShowAgainCookie: `introjs-dontShowAgain-${this.tourNameValue}`,
      dontShowAgain: true,
    })
    if(this.autoStartValue){
      this.start()
    }
    
  }
  
  start(){
    this.introJsInsance.start()
  }

  startOver(){
    this.introJsInsance.setOption("dontShowAgain", false).start()
  }
  
}