import { Controller } from '@hotwired/stimulus'; 


export default class extends Controller {
  
  static values = { userId: Number}
  static targets = ["query", "queryContainer", "userId", "userTray"];
  
  initialize(){
    this.boundHandleSelect = this.handleSelect.bind(this)
    this.boundHandleTurboFrameRender = this.handleTurboFrameRender.bind(this)
    this.boundHandleAjaxSuccess = this.handleAjaxSuccess.bind(this)
  }

  connect() {
    console.log(`${this.identifier} is connected.`)
    this.initTypeahead()
    $(this.element).on('typeahead:select', this.boundHandleSelect)
    $(this.element).on('typeahead:cursorchange', this.boundHandleSelect)
    $(this.element).on('turbo:frame-render', this.boundHandleTurboFrameRender)
    document.addEventListener('ajax:success', this.boundHandleAjaxSuccess)
  }
  
  disconnect(){
    $(this.element).off('typeahead:select')
    $(this.element).off('turbo:frame-render')
    document.removeEventListener('ajax:success', this.boundHandleAjaxSuccess)
  }

  // horrible work around to startOver after form submission via rails-ujs
  handleAjaxSuccess(event){
    console.log('handleAjaxSuccess', event)
    this.startOver(event)




  }

  async handleTurboFrameRender(event){
    
    console.log(`turbo:frame-render received!`)
    const regex = /.*widget_users\/(\d*)$/ // i.e. /admin/widget_users/123456
    const url = new URL(event.originalEvent.detail.fetchResponse.response.url).pathname
    console.log('url is', url)
    if(regex.test(url)){
      console.log(`the url ${url} has a user id at the end`)
      const userId  = regex.exec(url)[1]
      console.log(`found userId ${userId}`)
      this.userIdValue = userId
    }

  }
  startOver(event){
    event.preventDefault()
    console.log("start over!")
    this.clearUserTray(event)
    // typeahead replicate the queryTarget...
    this.queryTargets.forEach( (elem) => { $(elem).typeahead('val', '') } )
    this.userIdValue = 0

  }


  clearUserTray(event){
    event.preventDefault()
    this.userTrayTarget.innerHTML = ""
  }
  
  hideSearch(event){
    event.preventDefault()
    $(this.queryContainerTarget).hide()
  }



  showSearch(event){
    event.preventDefault()
    $(this.queryContainerTarget).show()
  }

  
  userIdValueChanged(value, previousValue){
    console.log(`userIdValue has been changed from ${previousValue} to ${value}`)
    $(this.userIdTarget).val(value)
  }
  
  async handleSelect(ev,value){
    console.log(`${this.identifier}#handleSelect()`)
    console.log(value)
    this.userIdValue = value.id
    //$(this.userIdTarget).val(value.id)
    // TODO replace this with Turbo visit
    const response = await fetch(`/admin/widget_users/${value.id}`, { headers:  {
      "Content-Type": "application/javascript",
      "Accept": "application/javascript"}})
    const html = await response.text();
    this.userTrayTarget.innerHTML = html

  }


  initTypeahead(){
    $(this.queryTarget).typeahead({
      hint: true,
      highlight: true,
      minLength: 1
    }, {
      name: 'users',
      limit: 20,
      display: 'email',
      source: this.getUsers(),
      templates: {
        suggestion: (user) =>  {
          let fullName = [user.title, user.first_name, user.last_name].join(' ')
          let institution = user.institution_name ? `<br><span class="small">${user.institution_name}</span>` : '' 
          return `<div>${fullName}
          <br>
          <span class="small">${user.email}</span>
          ${institution}
          </div>`
        },
        // footer: this.buttonShowUserFormTemplate(),
        // empty: this.buttonShowUserFormTemplate()
      }
    });
  }
    
  


  getUsers(){
    return new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('email'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/admin/users/autocomplete?q=%QUERY',
        wildcard: '%QUERY',
        transform: (res) => {
          return res.users
        }
      }
    });

  }

  buttonShowUserFormTemplate(){
    const html = `<div>
    <a href="#" class="button is-primary">
      create a user
    </a>
    </div>`;
    return html
  }

  
  
}