// This is an ugly hack to work around the fact that I don't know how to configure active storage files/attachements to preview correctly
const replaceUrlForAnimatedElements = () =>  {
  let attachments = document.querySelectorAll('action-text-attachment');
  attachments.forEach(function (attachment) {
    let contentType = attachment.getAttribute("content-type");
    if (contentType === "image/gif") {
      const url = attachment.getAttribute("url");
      let childImg = attachment.querySelector('img');
      let parentFigure = attachment.querySelector('figure');
      childImg.setAttribute("src", url);
      //childImg.style.setProperty("box-shadow", "5px 9px 15px 5px rgba(0.1, 0.1, 0.1, 0.1)");
      // if (!window.utils.matches) {
      //   parentFigure.style.setProperty("padding", "0 10% 0 10%", "important")
      // }
    }
    if (contentType === "video/mp4") {
      const url = attachment.getAttribute("url");
      let childImg = attachment.querySelector('img');
      childImg.remove();
      let videoEl = document.createElement('video');
      let sourceEl = document.createElement('source');
      videoEl.setAttribute("width", "80%");
      videoEl.setAttribute("height", "auto");
      videoEl.setAttribute("controls", "");
      videoEl.setAttribute("autoPlay", "");
      videoEl.setAttribute("loop", "");
      //videoEl.style.setProperty("box-shadow", "5px 9px 15px 5px rgba(0.1, 0.1, 0.1, 0.1)");
      sourceEl.setAttribute("src", url);
      sourceEl.setAttribute("type", "video/mp4");
      videoEl.append(sourceEl);
      attachment.querySelector('figure').append(videoEl);
    }
  })
}





document.addEventListener("DOMContentLoaded", function(){

  // make all links made in trix open in a new tab
  $('.add-target-blank-to-links a').attr('target','_blank');
  // make all links to a zoom meeting a button
  $('.add-target-blank-to-links a[href*=zoom]').addClass('button is-secondary').css('text-decoration','none');
  // hack the pre tag to display raw html (security issue here)
  document.querySelectorAll('.trix-content pre').forEach(element => {
    let doc = new DOMParser().parseFromString(element.innerHTML, "text/html");
    let html = doc.documentElement.textContent;
    element.insertAdjacentHTML('afterend', html);
    element.remove()
  });

  replaceUrlForAnimatedElements()

});