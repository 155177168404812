import { Controller } from '@hotwired/stimulus'; 
export default class extends Controller {
  static targets = ["content", "close", "open"];
  static values = {
    url: String
  }
  
  connect(){
    console.log(`${this.identifier} connected`)
  }
  
  open(event){
    // this is workaround b/c the normal event.params does not work for some reason
    event.preventDefault()
    const elem = event.target.closest('[data-drawer-url-param]')
    if(elem){
      this.urlValue = elem.dataset.drawerUrlParam;
    }
    console.log(this.urlValue);
    Turbo.visit(this.urlValue, {frame: 'help'})
    if(!this.contentTarget.classList.contains("open")){
      //this.contentTarget.style.width = "33%"
      this.contentTarget.classList.toggle("open");
      this.contentTarget.classList.toggle("px-2");
      this.openTarget.classList.toggle('is-hidden')
      this.closeTarget.classList.toggle('is-hidden')

    }
  }
  close(){
    //this.contentTarget.style.width = "0px"
    this.contentTarget.classList.toggle("open");
    this.contentTarget.classList.toggle("px-2");
    this.openTarget.classList.toggle('is-hidden')
    this.closeTarget.classList.toggle('is-hidden')
  }

  
}