import { Controller } from '@hotwired/stimulus'; 


export default class extends Controller {
  
  
  connect() {
    $(this.element).find('.remove_select_class_from_vertical_select').removeClass('select');
  }
  
}