import { Controller } from 'stimulus'; 
import Chart from 'chart.js/auto';

const CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
};
export default class extends Controller {
  
  static values = { institutionId: Number, translations: Object }

  connect(){
    console.log(`${this.identifier} is connected. will generate chart for institutionId: ${this.institutionIdValue}`)
    console.log(this.translationsValue)
    this.render_video_watchers_by_channel_chart()
    this.render_video_watchers_by_ownership_chart()
    this.render_event_listeners_chart()
  }

  
  render_video_watchers_by_ownership_chart(){
    const ctx = document.getElementById('video-watchers-by-ownership');
    
    this.getOwnershipData().then(data => {
      const chartData = {
        labels: [this.translationsValue.video_watchers_by_ownership.owned_by_institution, this.translationsValue.video_watchers_by_ownership.owned_by_others],
        datasets: [{
            barThickness: 30, 
            data: [data.owned_by_institution, data.owned_by_others],
            backgroundColor: [CHART_COLORS.green, CHART_COLORS.blue],
        }]
      };
      
      const config = {
        type: 'bar',
        data: chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
              position: 'right',
            },
            title: {
              display: false,
              text: 'Video Watchers by Ownership'
            }
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      };

      const videoWatchersChart = new Chart(ctx, config);
    })
  }

  async getOwnershipData(){
    const response = await fetch(`/admin/institutions/${this.institutionIdValue}/stats/video_watchers_by_ownership`);
    const data = await response.json()
    return data;
  }


  render_video_watchers_by_channel_chart(){
    const ctx = 'video-watchers-by-channel';
    this.getData().then(data => {
      data.datasets[0].backgroundColor = this.pieChartColors()
      
      const config = {
        type: 'pie',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'right',
              labels:{
                usePointStyle: true,

              },
            },
            title: {
              display: false,
              text: 'Viewers by Speciality',
              position: 'top'
            }
          }
        }
      };
    
      console.log(config)
      const videoWatchersChart = new Chart(ctx, config);
    })
  }



  render_video_watchers_timeseries_chart(){
    const ctx = 'video-watchers';
    this.getData().then(data => {
      const config = {
        type: 'line',
        data: data,
        options: {
          plugins: {
            title: {
              display: false,
              text: 'Watchers'
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true
            }
          }
        }
      };
    
      
      const videoWatchersChart = new Chart(ctx, config);
      
    })
  }

  pieChartColors(){
    return Object.values(CHART_COLORS)
  }

  async getData(){
    const response = await fetch(`/admin/institutions/${this.institutionIdValue}/stats/video_watchers_by_channel`);
    const data = await response.json()
    return data;

  }

  render_event_listeners_chart(){
    const ctx = document.getElementById('event-listeners-by-event');
    console.log("render_event_listeners_chart")
    this.getEventListenersData().then(data => {
      
      const chartData = {
        labels: data.map(d => new Date(d.event_date).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' })),
        datasets: [{
            label: 'Participants',
            barThickness: 30,
            data: data.map(d => d.event_listeners_count),
            backgroundColor: CHART_COLORS.blue,
        }]
      };
      
      const config = {
        type: 'bar',
        data: chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
              position: 'right',
            },
            title: {
              display: false,
              text: 'Event Listeners Count'
            },
            tooltip: {
              callbacks: {
                title: function(context) {
                  return data[context[0].dataIndex].event_headline;
                },
                label: function(context) {
                  return 'Participants: ' + context.formattedValue;
                }
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      };
      
      const eventListenersChart = new Chart(ctx, config);
    })
  }

  async getEventListenersData(){
    const response = await fetch(`/admin/institutions/${this.institutionIdValue}/stats/event_listeners_stats_by_event`);
    const data = await response.json()
    return data;
  }

  
}