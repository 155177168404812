import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["modal", "selectedChannels"];
  static values = {showModalOnLoad: Boolean, url: String}
  
  connect() {
    if(this.showModalOnLoadValue){
      this.popupModal()
    }
    this.renderSelectedChannels()
    this.selectedChannelsTarget.addEventListener('click', function(e){
      if(e.target.classList.contains('el-channel')){
        e.preventDefault()
        e.stopPropagation()
        return false
      }
    })
    
  }
  
  popupModal(){
    $(this.modalTarget).addClass('is-active')
    let url = this.urlValue
    $.getScript(url)
  }

  renderSelectedChannels(){
    fetch(this.urlValue)
    .then(response => response.text())
    .then(html => this.selectedChannelsTarget.innerHTML = html)
  }


}