import { Controller } from '@hotwired/stimulus';
import Swiper, { Navigation, Pagination } from 'swiper';

export default class extends Controller {
  static values = {
    options: Object
  }
  
  connect(){
    Swiper.use([Navigation, Pagination]);
    console.log(`${this.identifier} is connected`)
    this.swiper = new Swiper(this.element, {
        ...this.defaultOptions(),
        ...this.optionsValue
    })
    console.log(this.swiper)
  }

 

  disconnect(){
    this.swiper.destroy()
    this.swiper = undefined
  }

  defaultOptions(){
    return {
      spaceBetween: 20,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        
      },


    }

  }
  
}