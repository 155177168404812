import { Controller } from 'stimulus';
import Sortable from 'sortablejs'; 
export default class extends Controller {
  static targets = ["items", "form"];
  
  static values = { collectionId: Number, sortUrl: String }
  connect(){
    console.log(`${this.identifier} for collection ${this.collectionIdValue} is connected`)
    this.sortable = Sortable.create(this.itemsTarget, {
      onUpdate: this._sortableOnUpdate.bind(this)
    });
  }

  createSuccess(event) {
    // This might look a little magical but its just deconstructing the standard rails-ujs event detail
    // Read more here: https://guides.rubyonrails.org/working_with_javascript_in_rails.html#rails-ujs-event-handlers
    const [_data, _status, xhr] = event.detail
    this.itemsTarget.innerHTML = xhr.response
    $(this.formTarget).find('#media_id').val('')
    
  }

  _sortableOnUpdate(evt){
    let csrfToken = $('[name="csrf-token"]')[0].content
    let order = {'order': this.sortable.toArray()}
    $.ajax({
      type: 'PATCH',
      headers: { 'X-CSRF-Token': csrfToken},
      url: this.sortUrlValue,
      data: order,
    });
  }
}