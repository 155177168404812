import consumer from '../channels/consumer';
import { Controller } from '@hotwired/stimulus'; 

export default class extends Controller {
  
  static targets = ["player", "info"]
  static values = { 
    playbackId: String, 
    eventId: Number, 
    playlist: Array, 
    currentIndex: {type: Number, default: 0},
    questionRoundUrl: String
  }
  
  connect() {
    console.log(`${this.identifier} is connected`)
    console.log('connect-playlist', this.playlistValue)
    this.playbackIdValue = this.playlistValue[this.currentIndexValue].playback_id
    this.infoTarget.innerHTML = this.playbackIdValue
    console.log('connect-mediaId', this.playbackIdValue)
    this.videoPlayer = document.querySelector('mux-player')
    this.videoPlayer.setAttribute('playback-id', this.playbackIdValue);
    // TBD the eventlistener does not work here...
    this.videoPlayer.addEventListener('ended', (event)=> {
      console.log("video ended going to next video. currentIndex is ", this.currentIndexValue)
      this.next()
    }, false)
    // this.videoPlayer.play().catch((e) => {
    //   console.log('playback did not work out. probably the first video', this.videoPlayer);
    // });
    
    this.channel = consumer.subscriptions.create({channel: "LivePlaybackChannel", event_id: this.eventIdValue}, {
      connected: this._cableConnected.bind(this),
      disconnected: this._cableDisconnected.bind(this),
      received: this._cableReceived.bind(this),
    });
  }
  
  
  next(){
    console.log(`next: currentIndex is ${this.currentIndexValue}`)
    if(this._isLastItem()){
      console.log('last item, checking if there is a question round')
      if(this.questionRoundUrlValue){
        console.log(this.questionRoundUrlValue)
        const divIds = ['question-round-slide','cf-player', 'cf-player-container']
        divIds.forEach((id) => {document.getElementById(id).classList.toggle('is-hidden')});
      }else{
        console.log("no question round url")
      }

    }else{
      this.currentIndexValue ++
      this._indexChanged()
    }
  }
  
  prev(){
    console.log('prev')
    if(this._isFirstItem()){
      console.log('first item!')
    }else{
      this.currentIndexValue --
      this._indexChanged()
    }
  }

  _indexChanged(){
    const divIds = ['placeholder-slide','mux-player', 'mux-player-container']
    this.playbackIdValue = this.playlistValue[this.currentIndexValue].playback_id
    this.infoTarget.innerHTML = this.playbackIdValue
    this.videoPlayer.setAttribute('playback-id', this.playbackIdValue);
    divIds.forEach((id) => {document.getElementById(id).classList.toggle('is-hidden')});
    setTimeout(
      ()=> {
        this.videoPlayer.play().catch((e) => {
          console.log('playback did not work out. probably the first video', this.videoPlayer);
        }).then((result) => {
          divIds.forEach((id) => {document.getElementById(id).classList.toggle('is-hidden')}); 
        })
      }, 1000
    )
  }
  
  
  
  
  _isLastItem(){
    return this.currentIndexValue === this.playlistValue.length - 1 
  }
  _isFirstItem(){
    return this.currentIndexValue === 0
  }

  _cableConnected() {
    // Called when the subscription is ready for use on the server
  }

  _cableDisconnected() {
    // Called when the subscription has been terminated by the server
  }

  _cableReceived(data) {
    // Called when there's incoming data on the websocket for this channel
    console.log(data)
    this.playbackIdValue = data.mediaId
  }
      
  
  
}
