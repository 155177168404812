import { Controller } from '@hotwired/stimulus';
import typeahead from 'typeahead.js';


export default class extends Controller {
  
  static targets = ["query", "institutionId","selectedInstitutionName"];
  
  initialize(){
    this.boundHandleSelect = this.handleSelect.bind(this)
    this.boundHandleChange = this.handleChange.bind(this)
  }

  connect() {
    console.log(`${this.identifier} is connected.`)
    this.initTypeahead()
    $(this.element).on('typeahead:select', this.boundHandleSelect)
    $(this.element).on('typeahead:cursorchange', this.boundHandleSelect)
    $(this.queryTarget).on('typeahead:change', this.boundHandleChange)
  }
  disconnect(){
    $(this.element).off('typeahead:select')
  }

  handleSelect(ev,value){
    if(value){
      console.log(`${this.identifier}#handleSelect() -> ${value.id}/${value.name}`)
      $(this.institutionIdTarget).val(value.id)
      if(this.hasSelectedInstitutionNameTarget){
        $(this.selectedInstitutionNameTarget).val(value.name)

      }
    }
    ev.stopImmediatePropagation()
  }

  handleChange(ev,value){
    // check if the user has changed manually the inst. name
    if(this.hasSelectedInstitutionNameTarget){
      let selectedName =  $(this.selectedInstitutionNameTarget).val() 
      if(value != selectedName){
        console.log(`typed name (${value}) is different than the selected name (${selectedName}). reset the institution id`)
        $(this.institutionIdTarget).val("")
        $(this.selectedInstitutionNameTarget).val("")
      }
    }
  }


  initTypeahead(){
    $(this.queryTarget).typeahead({
      hint: false,
      highlight: true,
      minLength: 1
      },{
      name: 'institutions',
      limit: 20,
      display: 'name',
      source: this.getInstitutions(),
      templates: {
        suggestion: (institution) =>  {
          return `<div>${institution.name}</div>`
        }
      }
    });
  }
 
  getInstitutions(){
    return new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/institutions/autocomplete?q=%QUERY',
        wildcard: '%QUERY',
        transform: (res) => {
          return res.institutions
        }
      }
    });

  }
}