import consumer from '../channels/consumer';
import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["message", "container"];
  static values = { eventId: Number, isNotificationPresent: Boolean}
  connect(){
    if(!this.isNotificationPresentValue){
      this.containerTarget.style.display = "none" 
    } 
    this.channel = consumer.subscriptions.create({channel: "EventNotificationsChannel", event_id: this.eventIdValue}, {
      connected: this._cableConnected.bind(this),
      disconnected: this._cableDisconnected.bind(this),
      received: this._cableReceived.bind(this),
    });

  }

  hide(){
    this.containerTarget.style.display = "none"
  }

  _cableConnected() {
    // Called when the subscription is ready for use on the server
  }

  _cableDisconnected() {
    // Called when the subscription has been terminated by the server
  }

  _cableReceived(data) {
    // Called when there's incoming data on the websocket for this channel
    this.containerTarget.style.display = 'block'
    this.messageTarget.innerHTML = data;
  }
}