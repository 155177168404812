const $ = require("jquery")
const inView = require('in-view')
import bulmaCollapsible from '@creativebulma/bulma-collapsible';
// based on https://www.digitalocean.com/community/tutorials/js-building-countdown-timer
// scrollspy for the poor:
// https://codepen.io/Chuloo/pen/vjWBmX
const handleView = item => {
  const linkEl = document.querySelector(`#link-${item}`);
    
  let offsetHeight = 0.6*(window.innerHeight)
  inView.offset({
    bottom:offsetHeight
  });
  
  inView(`#${item}`)
    .on("enter", () => linkEl.classList.add('is-active'))
    .on("exit", el  => linkEl.classList.remove('is-active'))
};







function countdownTimer() {
  $('.countdown').each(function(){
    const start = $(this).data('event_starts_at')
    const startDate = new Date(start)
    const difference = startDate - new Date();
    if (difference > 0) {
      $(this).show()
      const parts = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
      $(this).find('.days').html(parts.days)
      $(this).find('.hours').html(parts.hours)
      $(this).find('.minutes').html(parts.minutes)
      $(this).find('.seconds').html(parts.seconds)
    }else{
      $(this).hide()
      $(this).find('.countdown-end').html('event started!');
    }
  })

}

$(document).ready(function(){
  // scrollspy the various sections in the event page
  ["schedule", "speakers", "register", "contact-form", "past-events"].forEach(handleView);
  const bulmaCollapsibleInstances = bulmaCollapsible.attach('.is-collapsible');
  countdownTimer();
  setInterval(countdownTimer, 1000);
  $('.modal-show').on('click', function(e){
    e.preventDefault()
    $('.modal').addClass('is-active')
  });
  
  if($('.message-thread').length > 0){
    $('.message-thread').animate({ scrollTop: $('.message-thread')[0].scrollHeight}, 1000);

  }
  $(document).on('click','.live-stream-messages-widget .panel-heading', function(){
    $('.panel .panel-block, .panel .panel-form').toggle();
    $('.icon .control').toggle();
  });

  // $('.video-packshot').on('click', function(e){
  //   const userId = ($(e.target).closest('.video-packshot').data('user-id'))
  //   if (!userId){
  //     e.preventDefault()
  //     $('.modal').addClass('is-active')

  //   }
  // });

  $(document).on('click', '#toogle-slide-show-nav', function(e){
    $('#slide-show-nav').toggleClass('is-hidden')
  })
  

  // pick channels for the event listener START
  $(document).on('click', '.launch-event-listener-channels', function(e){
    e.preventDefault()
    $('.modal').addClass('is-active')
    $.getScript($(e.target).data('url'))
  })

  document.addEventListener('event-listener-channels:change', function(e){
    console.log('event-listener-channels:change')
    if($('#topics').length){
      $.getScript($('#topics').data('url'))
    }
  });
  // load the topics on load
  if($('#topics').length){
    $.getScript($('#topics').data('url'))
  }


  // pick channels for the event listener END

  // show / hide sponsors logo

  
  
  // if($('#teaser-video').length){
  //   const teaser_media_id = $('#teaser-video').data('teaser_media_id')
  //   const VOD_CONFIG = {
  //     playlist: `https://cdn.jwplayer.com/v2/media/${teaser_media_id}`,
  //     repeat: true,
  //     logo: {
  //       file: "/docflix-logo.png",
  //       link: '#register-now',
  //       hide: "false",
  //       position: "top-right"
  //     }
  //   };
  //   const playerInstance = jwplayer('teaser-video').setup(VOD_CONFIG);
  // }

  

})

