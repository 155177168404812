$( document ).ready(function() {
  $('form#search-form select#channel').on('change', function(){
    let form = document.querySelector('form#search-form')
    Rails.fire(form, 'submit')
  });
  $('input#q').on('input',function(){
   
    if($(this).val().length > 2){
      let form = document.querySelector('form#search-form')
      Rails.fire(form, 'submit')
    }
  })
  
  // transcribe-api
  
  $('#get-media-info').on('click', function(e){
    let url =  $(e.target).data('url')
    $.ajax({
      url: url, 
      method: 'GET',
      success: function(data){
        console.log(data)
        if(data.ended_at){
          $('#media-status').html(`
          <div class="alert alert-success" role="alert">
          🎉👏 READY, no need for further processing
          </div>
          `)
        }
        $(e.target).find('.spinner-border').toggleClass('d-none')
        $('#docflix-transcribe-response').html(JSON.stringify(data,null, 2))
      },
      beforeSend: function() {
        $(e.target).find('.spinner-border').toggleClass('d-none')
        $('#docflix-transcribe-response').html('loading...')
      }
    })
  });
  $('#process-media').on('click', function(e){
    let url =  $(e.target).data('url')
    $.ajax({
      url: url, 
      method: 'POST',
      success: function(data){
        console.log(data)
        $(e.target).find('.spinner-border').toggleClass('d-none')
        $('#docflix-transcribe-response').html(JSON.stringify(data,null, 2))
      },
      beforeSend: function() {
        $(e.target).find('.spinner-border').toggleClass('d-none')
        $('#docflix-transcribe-response').html('loading...')
      }
    })
  });
  $('#destroy-media').on('click', function(e){
    let url =  $(e.target).data('url')
    $.ajax({
      url: url, 
      method: 'POST',
      success: function(data){
        console.log(data)
        $(e.target).find('.spinner-border').toggleClass('d-none')
        $('#docflix-transcribe-response').html(JSON.stringify(data,null, 2))
      },
      beforeSend: function() {
        $(e.target).find('.spinner-border').toggleClass('d-none')
        $('#docflix-transcribe-response').html('loading...')
      }
    })
  });

  $('.transcription-editor .fetch-transcription').on('click', function(e){
      $('.button.fetch-transcription').addClass('is-loading')
      let langauge = $('select.language').val()
      let url = $('.transcription-editor').data('url')
      fetch(`${url}${langauge}`)
      .then(response => response.json())
      .then(data => $('textarea.transcription-html').html(data.transcription))
      .then(data => console.log('fetched'))
      .then(data => $('.button.fetch-transcription').removeClass('is-loading'))
  })


  $('.transcription-editor .update-transcription').on('click', function(e){
    $('.button.update-transcription').addClass('is-loading')
    let langauge = $('select.language').val()
    let url = $('.transcription-editor').data('url')
    fetch(`${url}${langauge}`, {method: 'PUT'})
    .then(response => response.json())
    .then(data => $('textarea.transcription-html').html(data.transcription))
    .then(data => console.log('fetched'))
    .then(data => $('button.update-transcription').removeClass('is-loading'))
})



});