import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["field", "button"];
  connect(){
    this.fieldTarget.setAttribute('disabled', 'true')
  }
  toggle(e){
    e.preventDefault()
    if(this.fieldTarget.getAttribute('disabled')){
      this.fieldTarget.removeAttribute('disabled')
      this.buttonTarget.querySelector('.lock-label').innerHTML = 'lock'

    }else{
      this.fieldTarget.setAttribute('disabled', 'true')
      this.buttonTarget.querySelector('.lock-label').innerHTML = 'unlock'
    }
    
  }
}