const addTimestampToTranscription = () => {
  console.log('fetched transcriptions')
  const sections = Array.from($('.transcription-body p'))
  sections.forEach((section, index) => {
    if((index+1) % 4 == 0 ){ // display the jump to ts every 4 captions
      let $section  = $(section)
      let position = parseInt($section.attr('s'))
      let html = `
<a class="seek-position badge is-outlined is-small" data-position="${position}">
  ${new Date(position * 1000).toISOString().substr(14, 5)}
</a>
`
      
      $section.prepend(html)
    }
  })
  $('.seek-position').on('click', (e) =>{
    e.preventDefault()
    let position = $(e.target).data('position') 
    let muxPlayer = document.querySelector("mux-player")
    muxPlayer.currentTime = position
  } )
}


$(document).ready(function(){
  $('.fetch-transcriptions').on('transcriptions:fetched', function(){
    addTimestampToTranscription()
  });
  

})