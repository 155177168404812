import { Controller } from '@hotwired/stimulus'; 

export default class extends Controller {
  
  static targets = ["player", 'currentPosition', 'mediaId']
  static values = { 
    mediaId: String,
  }
  
  
  connect() {
    this.mediaIdTarget.innerHTML = this.mediaIdValue
    this.videoPlayer = document.querySelector('mux-player')
    this.videoPlayer.addEventListener('play', (event)=> {
      console.log("video play")
      this.play()
    })
    this.videoPlayer.addEventListener('pause', (event)=> {
      console.log("video pause")
      this.pause()
    })
    this.videoPlayer.addEventListener('ended', (event)=> {
      console.log("video ended")
      
    })

    this.videoPlayer.addEventListener('timeupdate', (event)=> {
      this.currentPositionTarget.value = this._formatAsHHMMSS(this.getPosition()) 
      
    })
  }
      
  
  
  play(){
    this.outputPosition()
  }

  pause(){
    this.outputPosition()
  }

  
  pauseVideo(){
    this.videoPlayer.pause()
  }

  disconnect() {
    
  }

  getPosition(){
    return this.videoPlayer.currentTime
  }

  _formatAsHHMMSS(timeInSeconds){
    return new Date(timeInSeconds * 1000).toISOString().substring(11, 19)
  }

  outputPosition(){
    console.log(this._formatAsHHMMSS(this.getPosition()))
  }
  
}
