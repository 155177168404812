import consumer from '../channels/consumer';
import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["slide"];
  static values = { url: String, slideShowId: Number }
  connect(){
    console.log('slide-show-controller is connected')
    this.channel = consumer.subscriptions.create({channel: "SlideShowsChannel", slide_show_id: this.slideShowIdValue}, {
      connected: this._cableConnected.bind(this),
      disconnected: this._cableDisconnected.bind(this),
      received: this._cableReceived.bind(this),
    });

  }

  urlValueChanged() {
    console.log("urlValueChanged", this.urlValue)
    if(this.urlValue.length > 0){
      this.playSlide()

    }
  }

  playSlide(){
    console.log(this.urlValue)
    fetch(this.urlValue)
    .then(response => response.text())
    .then(html => this.slideTarget.innerHTML = html)
  }

  _cableConnected() {
    // Called when the subscription is ready for use on the server
  }

  _cableDisconnected() {
    // Called when the subscription has been terminated by the server
  }

  _cableReceived(data) {
    // Called when there's incoming data on the websocket for this channel
    console.log(`${this.identifier} has received a WS message`, data)
    this.urlValue = data.url
    // this.containerTarget.style.display = 'block'
    // this.messageTarget.innerHTML = data;
  }
}