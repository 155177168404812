import consumer from '../channels/consumer';
import { Controller } from 'stimulus'; 
export default class extends Controller {
  //static targets = ["message", "container"];
  static values = { eventId: Number, filter: Object}
  connect(){
    //this.containerTarget.style.display = "none"
    this.channel = consumer.subscriptions.create({channel: "LiveStreamMessagesChannel", event_id: this.eventIdValue}, {
      connected: this._cableConnected.bind(this),
      disconnected: this._cableDisconnected.bind(this),
      received: this._cableReceived.bind(this),
    });

  }

  selectTag(event) {
    let tag = $(event.target).val()
    let filter = this.filterValue
    filter[tag] = $(event.target).is(':checked')
    this.filterValue = filter
    $("#live-stream-messages").find(`[data-tags='${tag}']`).toggleClass('is-hidden')
    console.log(this.filterValue)
  }

  _cableConnected() {
    // Called when the subscription is ready for use on the server
  }

  _cableDisconnected() {
    // Called when the subscription has been terminated by the server
  }

  _cableReceived(data) {
    // Called when there's incoming data on the websocket for this channel
    let tag = $(data).data('tags')
    //console.log(tag)
    if(this.filterValue[tag] === true){
      $(data).hide().prependTo("#live-stream-messages").fadeIn();
    }else{
      $(data).addClass('is-hidden').prependTo("#live-stream-messages")
    }

  }
}