import { Controller } from "@hotwired/stimulus";
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import ActiveStorageUpload from "@excid3/uppy-activestorage-upload";
import ImageEditor from '@uppy/image-editor';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["trigger", "preview"];
  static values = { fieldName: String, url: String, cropperOptions: Object };

  connect() {
    this.triggerTarget.addEventListener("click", (event) => event.preventDefault());
    let directUploadUrl = document.querySelector("meta[name='direct-upload-url']").getAttribute("content");

    this.uppy = new Uppy({
      autoProceed: false,
      allowMultipleUploads: false,
      debug: true
    })
    .use(ActiveStorageUpload, {
      directUploadUrl: directUploadUrl
    })
    .use(Dashboard, {
      trigger: this.triggerTarget,
      closeAfterFinish: true,
      autoOpenFileEditor: true
    })
    .use(ImageEditor, {
      target: Dashboard,
      quality: 0.8,
      cropperOptions: this.cropperOptionsValue
    })
    .on('complete', (result) => {
      this.element.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element));

      result.successful.forEach(file => {
        this.appendUploadedFile(file, this.fieldNameValue);
        this.setPreview(file);
      });

      this.uppy.getFiles().forEach(file => this.uppy.removeFile(file.id));
    });
  }

  disconnect() {
    this.uppy.close();
  }

  appendUploadedFile(file, fieldName) {
    const hiddenField = document.createElement('input');

    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', fieldName);
    hiddenField.setAttribute('data-pending-upload', true);
    hiddenField.setAttribute('value', file.response.signed_id);

    this.element.appendChild(hiddenField);

    // make AJAX request to server to attach image
    const url = this.urlValue;
    const signedId = file.response.signed_id;
    if(url){
      Rails.ajax({
        url: url,
        type: "PATCH",
        data: `signed_id=${signedId}`,
        success: function(data) {
          console.log('Successfully attached image');
        },
        error: function(data) {
          console.log('Failed to attach image');
        }
      });
    }
  }

  setPreview(file) {
    let preview = this.previewTarget;
    if (preview) {
      let src = (file.preview) ? file.preview : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY";
      preview.src = src;
    }
  }
}
