import { Controller } from '@hotwired/stimulus'; 


const $ = require("jquery")
const INTERVAL = 10000

export default class extends Controller {
  
  static targets = ["player"]
  static values = { 
    mediaId: String,
    playerType: String, // video-js or cf
    jwt: String, 
    sessionId: String,
    startWatchTime: Number,
    intervalId: Number,
  }
  
  
  connect() {
    this.startWatchTimeValue = this._currentSeconds(),
    this.intervalIdValue = null
    if(this.playerTypeValue === 'mux'){
      this.videoPlayer= document.querySelector("mux-player");
    }else if(this.playerTypeValue === 'cf'){ 
      this.videoPlayer = Stream(document.getElementById('cf-player'))
    }else{
      this.videoPlayer = this.playerTarget.querySelector('video')
    }
    // console.log(this.videoPlayer)
    // const player = videojs(this.videoPlayer)
    // console.log(player)
    this.videoPlayer.addEventListener('play', (event)=> {
      console.log("video play")
      this.play()
    })
    this.videoPlayer.addEventListener('pause', (event)=> {
      console.log("video pause")
      this._stopWatching()
    })
    this.videoPlayer.addEventListener('ended', (event)=> {
      console.log("video ended")
      this._sendRequest('complete')
      this._stopWatching()
    })
  }
      
  play(){
    this.startWatchTimeValue = this._currentSeconds()
    this._sendRequest('play')
    this.intervalIdValue = setInterval(() => {
              this._watchSeconds(INTERVAL / 1000)
    }, INTERVAL)
  }

  
  disconnect() {
    this._stopWatching()
  }

  _currentSeconds() {
    return Math.round(new Date() / 1000)
  }

  _stopWatching() {
    const currentTime = this._currentSeconds()
    const watchedTime = currentTime - this.startWatchTimeValue
    this._watchSeconds(watchedTime)
    clearInterval(this.intervalIdValue)
  }

  _watchSeconds(value) {
    let position = this.getPosition()
    let url = `/api/v1/videos/${this.mediaIdValue}/watch?value=${value}&session_id=${this.sessionIdValue}&position=${position}`
    if (this.jwtValue !== null) url += `&jwt=${this.jwtValue}`
    $.ajax({
      method: 'POST',
      url: url,
      success: (data) => {
        console.log(data)
      }
    })
    this.startWatchTimeValue = this._currentSeconds()
  }

  getPosition(){
    return this.videoPlayer.currentTime
  }


  _sendRequest(endpoint) {
    let url = `/api/v1/videos/${this.mediaIdValue}/${endpoint}?session_id=${this.sessionIdValue}`
    if (this.jwtValue !== null) url += `&jwt=${this.jwtValue}`
    $.ajax({
      method: 'POST',
      url: url,
      success: (data) => {
        console.log(data)
      }
    })
  }

  
}
