
import 'jquery'


const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
import "../stylesheets/application.scss";

import "components/navigation/navigation";

import "components/check_email/check_email";
import "components/videos/videos";

import "components/video/fetch_transcriptions";

import "components/shared/carousel";

import "components/event/event";
import "components/event/credit_points";

import "components/shared/messages/messages";
import "components/shared/tabs";
import "components/shared/modals";
import "components/shared/dropdowns";

import "channels";
import "controllers"
import "components/trix-content"


import Rails from "@rails/ujs";
window.jQuery = $;
window.$ = $;
window.Rails = Rails;
Rails.start();

require("slick-carousel")

import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"
//import "@fortawesome/fontawesome-free/js/all";
//import 'swiper/swiper-bundle.min.css'

//import videojs from 'video.js';
//window.videojs = videojs;
import { Application } from "stimulus"
import Clipboard from "stimulus-clipboard"

import "@hotwired/turbo-rails"
import "@hotwired/stimulus"

import "swiper"
import "swiper/swiper-bundle.css"

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

const application = Application.start()
application.register("clipboard", Clipboard)
