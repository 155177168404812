import { Controller } from 'stimulus';

const axios = require('axios')
/**
   * How often we should be checking for an update to the live status.
   * 10 seconds is generally a good frequency, it is not useful to check more often.
   */
 const UPDATE_FREQUENCY = 10 * 1e3;

 /**
  * The code of the error which the player may raise if the livestream goes offline or finishes.
  */
 const LIVESTREAM_COMPLETE_ERROR = 230001;

 /**
  * The code of the error emitted by JW Player's HLS implementation if buffering stalls.
  * This may happen when a livestream (suddenly) finishes and no more segments are available to buffer.
  * In this case we'll switch back to VOD playback.
  */
 const HLS_BUFFER_STALL_WARNING = 334001;

 /**
  * The maximum number of times we'll try before giving up configuring a player.
  * @type {number}
  */
 const MAX_RETRIES = 5;

const VOD_CONFIG = {
  playlist: 'https://cdn.jwplayer.com/v2/media/C17wSlgG',
  // Repeat the VOD indefinitely while we wait for the live event stream to become available.
  repeat: true,
};

/** The identifier of the current/last played event. */
let currentEventId;

/** An id used by the setInterval()/clearInterval() functions in order to manage the update loop. */
let intervalId;

let toggleVideoInterval;
export default class extends Controller {
  static values = { channelId: String, startsAt: Number, hideDocflixLogo: Boolean, ignoreCountdown: Boolean }
  static targets = ["countdown", "video", "chat"]
  connect(){
    console.log(`live-stream-controller connecting ${Date.now()}`)
    this.playerInstance = jwplayer('player').setup(VOD_CONFIG);
    this.attachEventListenersToPlayer()
    this.checkChannelStatus()
    this.toggleVideo()
    //window.history.pushState({}, `LiveStream ${this.channelIdValue}` , `live_stream#live_channel_id=${this.channelIdValue}`)

  }

  toggleVideo(){
    console.log(`toggleVideo ${Date.now()}`)
    if(!toggleVideoInterval){
      console.log("start toggleVideoInterval")
      toggleVideoInterval = setInterval(this.toggleVideo.bind(this), 3000)
    }
    if(this.hasEventStarted()){
      console.log("event has started. show video player")
      this.videoTarget.classList.remove('is-hidden')
      this.chatTarget.classList.remove('is-hidden')
      this.countdownTarget.classList.add('is-hidden')

      toggleVideoInterval = clearInterval(toggleVideoInterval)
    }
  }

  /**
   * Periodically checks whether the specified livestream channel is available, and if it is, configures the player
   * to start playing it.
   */
   checkChannelStatus() {
    // console.log(`checkChannelStatus ${Date.now()}`)
    if (!intervalId) {
      console.log(`Waiting for Live Channel ${this.channelIdValue} to become active.`)
      // Make sure to execute this method every UPDATE_FREQUENCY milliseconds.
      intervalId = setInterval(this.checkChannelStatus.bind(this), UPDATE_FREQUENCY);
    }
    if(!this.hasEventStarted()){
      console.log(`checkChannelStatus: event has not yet started ${Date.now()}`)
      return
    }
    this.getChannelStatus(this.channelIdValue).then((channelStatus) => {
      console.log(`Received channel status: %O`, channelStatus);
      if (channelStatus['status'] === 'active') {
        console.log(`channel status is active ${Date.now()}`)
        // Determine the id of the active event based on the returned status.
        const eventId = channelStatus['current_event'];

        // Check if we have seen this eventId before.
        if (currentEventId === eventId) {
          // The eventId returned by the API was not a *new* event id.
          // Ignore it and continue polling until we see a new id.
          return;
        }
        currentEventId = eventId;

        // Stop polling the channel status.
        intervalId = clearInterval(intervalId);

        // Attempt to configure the player in order to start livestream playback.
        this.configurePlayer(eventId).catch((error) => {
          console.log(`Failed to start live event stream playback: ${error}`)
        });
      }
    }, (error) => {
      console.log(`Unable to fetch the channel status for ${channelId}: ${error}`)
      // If we fail to retrieve the channel status, then give up.
      intervalId = clearInterval(intervalId);
    });
  }

  hasEventStarted(){
    if(this.ignoreCountdownValue) return true
    let nowInMs = new Date().getTime()
    // console.log(`${new Date( this.startsAtValue - nowInMs).toISOString().substr(11, 8)}`)
    return (nowInMs > this.startsAtValue)
  }

  attachEventListenersToPlayer(){
    // Register an event listener that triggers when the JW Player has finished playing all
    // elements in its playlist. In this demo, this event is triggered when livestream playback
    // has finished.
    this.playerInstance.on('playlistComplete', this.handleLivestreamFinished.bind(this));

    // Register an event listener that triggers when the player emits an error.
    this.playerInstance.on('error', (error) => {
      // Check if the error may have been because the livestream stopped updating, in this case
      // we'll switch back to playing the VOD.
      if (this.playerInstance.getPlaylistItem().mediaid !== currentEventId) {
        // Ignore errors during VOD playback.
        return;
      }
      if (error.code === LIVESTREAM_COMPLETE_ERROR) {
        this.handleLivestreamFinished();
      }
    });
  }

  handleLivestreamFinished() {
    if (intervalId) {
      // We are already checking for a livestream.
      // In this state there should not be a reason to re-initialize the player -- it should already be in the correct
      // state.
      return;
    }
    console.log('Detected livestream completion. Switching to VOD playback.');

    // Enable looping of media.
    this.playerInstance.setConfig({repeat: true});
    // Reload the VOD playlist.
    this.playerInstance.load(VOD_CONFIG.playlist);
    if (this.channelIdValue) {
      // Start checking for a new event.
      this.checkChannelStatus();
    }
    this.playerInstance.play();
  }

  

  /**
   * (Re-)configures the active playerInstance to play the livestream identified by eventId.
   */
  async configurePlayer(eventId) {
    
    // There may be a slight delay between the livestream becoming available, and its playlist to become available.
    // Therefore, we first attempt to fetch the playlist for the new live event, as soon as we have successfully fetched
    // a playlist, we will load it on the player and start playback of the livestream.
    console.log(`configurePlayer ${Date.now()}`)
    let playlist;
    let attempts = 0;
    while (!playlist) {
      try {
        playlist = await this.getPlaylist(eventId);
      } catch (e) {
        ++attempts;
        console.error(e);
        if (attempts >= MAX_RETRIES) {
          throw e;
        }
        // Retry with exponential backoff, i.e. first retry after 5, 10, 20, 40, 80 seconds
        // after which we ultimately give up.
        await this.sleep(2 ** (attempts - 1) * 5 * 1000);
      }
    }

    // Once a playlist is available, use it to configure the player.
    this.playerInstance.setConfig({
      repeat: false,
      autostart: true
    });
    this.playerInstance.load(playlist.playlist);
    // Start playback
    await this.sleep(1000)
    this.playerInstance.play();
    console.log(`Playing live event stream with id '${eventId}'.`)
  }

  
  
  
  
  
  
  /**
   * Utility function to fetch a JSON document.
   * @param url
   */
  async fetchJSON(url) {
    const response = await axios.get(url)
    console.log(response)
    if (!response.status === 200) {
      throw new Error(`Unable to fetch ${url}: ${response.statusText}`);
    }
    return response.data;
      
  }

  /**
   * Fetches the current status of a Live Channel.
   * Returns a promise that will yield the status for a particular channel.
   *
   * @param channelId The channel to fetch the status for.
   */
  getChannelStatus(channelId) {
    return this.fetchJSON(`https://cdn.jwplayer.com/live/channels/${channelId}.json`);
  }

  /**
   * Fetches a JW Platform feed for a particular media item.
   *
   * @param mediaId The media id to fetch a single item playlist for.
   */
  getPlaylist(mediaId) {
    return this.fetchJSON(`https://cdn.jwplayer.com/v2/media/${mediaId}`);
  }

  /**
   * A simple utility method which can be used to wait for some time between retries.
   *
   * @param ms The amount of milliseconds to wait between retries.
   */
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }







} 