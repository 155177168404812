import { Controller } from '@hotwired/stimulus';
import { Space, SpaceEvent, getUserMedia } from "@mux/spaces-web";


export default class extends Controller {
  static values = { jwt: String }
  connect(){
    console.log(`${this.identifier} is connected`)
    this.localParticipant = null
    this.localParticipantEl = document.getElementById("local-video");
    this.participantsEl = document.getElementById("remote-videos");
    // Add a callback for the "Join" button
    document.getElementById("join-button").addEventListener("click", (e) => {
      e.target.disabled = true;
      this.join();
    });
   
    document.getElementById("share-button").addEventListener("click", (e) => {
      e.target.disabled = true;
      this.shareScreen();
    });

    // Bind event handlers to the controller instance
    this.addTrack = this.addTrack.bind(this);
    this.removeTrack = this.removeTrack.bind(this);
    this.removeParticipant = this.removeParticipant.bind(this);
    this.shareScreen = this.shareScreen.bind(this);
  }
  
  async join() {
    // Instantiate our space
    let space = new Space(this.jwtValue);
    // Setup event listeners for other people joining and leaving
    space.on(SpaceEvent.ParticipantTrackSubscribed, this.addTrack);
    space.on(SpaceEvent.ParticipantTrackUnsubscribed, this.removeTrack);
    space.on(SpaceEvent.ParticipantLeft, this.removeParticipant);
    // Join the Space
    this.localParticipant = await space.join();
    // Publish and display our local tracks
    let localTracks = await getUserMedia({
      audio: true,
      video: true,
    });
    await this.localParticipant.publishTracks(localTracks);
    console.log(this.localParticipantEl)
    console.log(localTracks)
    localTracks.forEach((track) => {
      track.attach(this.localParticipantEl);
    });
  }

  // Creates or updates a <video> element in the page when a participant's track becomes available
  async addTrack(participant, track) {
    let remoteVideo = document.getElementById(participant.connectionId);
    if (!remoteVideo) {
      const el = document.createElement("video");
      el.id = participant.connectionId;
      el.width = 400;
      el.height = 225;
      el.autoplay = true;
      el.controls = true;
      this.participantsEl.appendChild(el);
      remoteVideo = el;
    }
    track.attach(remoteVideo);
    
  }

  // Removes a participant's track when it is no longer available
  async removeTrack(participant, track) {
    const remoteVideo = document.getElementById(participant.connectionId);
    track.detach(remoteVideo);
  }

  // Removes the appropriate <video> element from the page when a participant leaves
  async removeParticipant(participant) {
    this.participantsEl.removeChild(document.getElementById(participant.connectionId));
  }


  async shareScreen() {
    // Get the screen or window being shared
    let stream = await navigator.mediaDevices.getDisplayMedia({
      video: true,
      audio: false
    });

    // Publish the screen sharing tracks with "video" kind
    let tracks = stream.getTracks();
    console.log(tracks)
    await this.localParticipant.publishTracks(tracks, { kind: 'video' });

    // Attach the tracks to the local participant video element
    tracks.forEach((track) => {
      track.attach(this.localParticipantEl);
    });

    // Enable the "Stop Sharing" button and disable the "Share" button
    document.getElementById("stop-sharing-button").disabled = false;
    document.getElementById("share-button").disabled = true;
  }

}