import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["content", "toggleIcon"];
  static classes = ["hidden"]
  static values = { isInitialyExpanded: Boolean, isExpanded: Boolean }
  connect(){
    if(this.isInitialyExpandedValue){
      let clazz = this.hasHiddenClass ?  this.hiddenClass : 'is-hidden'
      this.contentTargets.forEach((t) => t.classList.remove(clazz));
      this.isExpandedValue = true
    }else{
      this.isExpandedValue = false
    }
  }
  
  toggle(){
    let clazz = this.hasHiddenClass ?  this.hiddenClass : 'is-hidden'
    this.contentTargets.forEach((t) => t.classList.toggle(clazz));
    this.isExpandedValue = !this.isExpandedValue
    console.log("isExpandedValue", this.isExpandedValue)
    
    
  }

  isExpandedValueChanged(){
    if(this.hasToggleIconTarget){
      if(this.isExpandedValue){
        this.toggleIconTarget.querySelector('i').classList.replace('fa-plus', 'fa-times')
      }else{
        this.toggleIconTarget.querySelector('i').classList.replace('fa-times', 'fa-plus')
      }
    }
  }
}