import consumer from '../channels/consumer';
import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["container"];
  static values = { userId: Number}
  connect(){
    console.log(`${this.identifier} is connected`)
    //this.containerTarget.style.display = "none"
    this.channel = consumer.subscriptions.create({channel: "UserNotificationsChannel", user_id: this.userIdValue}, {
      connected: this._cableConnected.bind(this),
      disconnected: this._cableDisconnected.bind(this),
      received: this._cableReceived.bind(this),
    });
    console.log(this.channel)

  }

  

  _cableConnected() {
    // Called when the subscription is ready for use on the server
  }

  _cableDisconnected() {
    // Called when the subscription has been terminated by the server
  }

  _cableReceived(data) {
    // Called when there's incoming data on the websocket for this channel
    if(data.match('ERROR')){
      data = '&#9940;&nbsp;' + data
    }else{
      data = '&#9989;&nbsp;' + data
    }
    this.containerTarget.insertAdjacentHTML('beforeend', `<div>${data}</div>`)
 
  }
}