import "./navigation.scss";

// const $ = require("jquery");
//
// $("#dropdown").on("click", (e) => {
//   e.preventDefault()
//
//   $("header .dropdown").removeClass('hidden');
// })
//
// $(".dropdown .close").on("click", (e) => {
//   e.preventDefault()
//
//   $("header .dropdown").addClass('hidden');
// })

document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }

  // pipedrive
  const openPipedriveLinks = Array.from(document.getElementsByClassName('open-pipedrive-chat'));

  openPipedriveLinks.forEach(link => {
    link.addEventListener('click', function handleClick(event) {
      event.preventDefault();
      console.log('link clicked', event);
      LeadBooster.trigger('open');
      
    });
  });


});
