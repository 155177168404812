const highlightActiveTab = (tabName) => {
  $(".tabs li").removeClass('is-active');
  $(`.tabs li[data-tab-name='${tabName}']`).addClass('is-active');
}
const fetchInitialTab = () => {
  const tabUrl = $('#content').data('tab-url')
  if(tabUrl){
    $.getScript(tabUrl)
  }
}

$( document ).ready(() => {
  $('.tab-link').on('click', function(){
    const tabName = $(this).parent().data('tab-name')
    const url = $(this).attr('href')
    const state = {
      url: url,
      tabName: tabName
    }
    if(window.history && window.history.pushState){
      history.pushState(state,'', window.location.pathname + '?tab=' + tabName);
    }
    highlightActiveTab(tabName)
  }
  );
  fetchInitialTab()
  // highlightActiveTab(tabName)
  
  
});

$(window).on('popstate', (event) =>  {
  if(event.originalEvent.state){
    const state = event.originalEvent.state
    $.getScript(state.url)
    highlightActiveTab(state.tabName)
  }
});