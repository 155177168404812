import "./check_email.scss";

document.addEventListener("DOMContentLoaded", function(){

  $('form input#user_email').on('change', function(e){
    let email = e.target.value
    let check_email_path = $(e.target).data('check_email_path')
    let path_contains_params = check_email_path.indexOf('?') > 0
    let param_prefix = path_contains_params ? '&' : '?' 
    let url = `${check_email_path}${param_prefix}email=${email}`
    
    $.get(url, function(data){
      if(data.status === 'user_found'){
        console.log(data)
        $('.check-email-is-negative').hide()
        $('.check-email-is-positive').show()
        $('input#check_email_is_positive').val('true')
        $('input[required]').removeAttr('required');
      }else{
        console.log('user not found. do nothing')
        $('.check-email-is-negative').show()
        $('.check-email-is-positive').hide()
        $('input#check_email_is_positive').val('false')
  
      }
    })
  
  
  })
})

