import "./logo.png";
import "./channels/cardiology.jpg";
import "./channels/dermatology.jpg";
import "./channels/gastroenterology.jpg";
import "./channels/gynecology.jpg";
import "./channels/neurology.jpg";
import "./channels/neurosurgery.jpg";
import "./channels/ophthalmology.jpg";
import "./channels/surgery.jpg";
import "./pdf.png"
import "./channels/traumatology.jpg";
import "./privacy.jpg";



