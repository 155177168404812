import { Controller } from '@hotwired/stimulus'; 

export default class extends Controller {
  connect() {
    console.log(this.identifier)
    var form = this.element.querySelector("form")
    var input = form.querySelector("input[type=file]");
    var submit = form.querySelector("input[type=submit]");
    input.addEventListener('change', function(event){
      submit.click()
    });
  }
}